<template>
    <div style="height: 56px;"></div>
    <div class="content-1">
        <div style="width: 100%;">
            <img style="z-index: -1; width: 100%;" src="@/assets/images/illit-youtube-banner.jpg">
        </div>

        <div style="display: grid; place-items: center;">
            <div class="channel-info">
                <div><h4>구독자:</h4><h1 style="display: inline;" id="subscribers"></h1><span> 명</span></div>
                <div><h4>조회수 합계: </h4><h1 style="display: inline;" id="total-views"></h1><span> 회</span></div>
                <div><h4>좋아요 수 합계: </h4><h1 style="display: inline;" id="total-likes"></h1><span> 개</span></div>
                <div><h4>댓글 수 합계: </h4><h1 style="display: inline;" id="total-comments"></h1><span> 개</span></div>
                <div><img  
                    :src="illit_logo_white" style="width: 200px; opacity: 0.85;"></div>
            </div>
        </div>

        <div style="display: grid; grid-template-columns: 50% 50%; border-top: none; padding: 0;">
            <div><h1>아일릿 채널</h1></div>
            <div style="display: flex; align-items: center; justify-content: right;"><span style="color: gray; ">  ⓘ 최근 갱신: {{ this.recentUpdateTime }}</span></div>
        </div>
        
        <div style="display: grid; place-items: center;">
            <div class="input-group" style="width: 100%; display: grid; grid-template-columns: 20% 60% 20%;">
                <div style="display: flex; align-items: center; justify-items: center;"><h3>영상 정보 일람</h3></div>
                <div style="display: flex; align-items: center; justify-items: center;">
                    <input @input="inputFilled" type="text" id="youtube-link-input" class="form-control" placeholder="유튜브 링크를 입력하세요..." aria-label="유튜브 링크를 입력하세요..." aria-describedby="button-addon2">
                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="white-space: nowrap">보내기</button>                    
                </div>
                <div></div>
            </div>      
            <div class="video-link-content" v-if="isInputFilled==true" style="display: grid; grid-template-columns: 50% 50%; width: 80%;">
                <div style="text-align: right;">
                    <iframe width="560" height="315" :src='`https://www.youtube.com/embed/${this.inputVideoId}?si=W_iueIgdzT8jAEYt`' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                    
                </div>
                <div style="display: flex; align-items: center;">
                    <div>
                        <h4>{{ this.inputVideoTitle }}</h4>
                        <br><h5>조회수 순위</h5><h2 style="display: inline;">{{ this.viewCountRanking }}</h2><span> / {{ this.videoCount }} 위</span><br><br>
                        <h5>좋아요 순위</h5><h2 style="display: inline;">{{ this.likeCountRanking }}</h2><span> / {{ this.videoCount }} 위</span><br><br>
                        <router-link :to='`/youtube/illit-channel/${this.inputVideoId}`'> <button type="button" class="btn btn-outline-info">자세히 보기</button></router-link> <a :href='`https://www.youtube.com/watch?v=${this.inputVideoId}`' target="_blank"><button type="button" class="btn btn-outline-primary">영상으로 이동</button></a>
                    </div>

                </div>
            </div>      
        </div>

        <div style="display: grid; grid-template-columns: 50% 50%;">
            <div id="view-top20" style="width: 100%; height: 800px;"></div>
            <div id="like-top20" style="width: 100%; height: 800px;"></div>
        </div>
        <div id="subscriber-chart"></div>
        <div id="video-count-chart"></div>
    </div>
</template>

<style scoped>
.channel-info {
    position: absolute;
    top: 12.5vh;
    display: grid;
    place-items: center;
    width: 90%;
    z-index: 1000;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 100%; 
}

.channel-info > div {
    color: rgba(255, 255, 255, 0.85);
}

.content-1 {
    display: grid;
    place-items: center;
    width: 100%;
}

.content-1 > div {
    border-top: lightgray solid 1px;
    width: 95%;
}

.content-1 > div > div {
    padding: 50px;
}

.video-link-content > div {
    padding: 50px;
    padding-top: 0;

}

#subscriber-chart {
    padding: 50px;
    border-top: lightgray solid 1px;
    width: 95%;
    height: 500px;
}
#video-count-chart {
    padding: 50px;
    border-top: lightgray solid 1px;
    width: 95%;
    height: 500px;
}
</style>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import { CountUp } from 'countup.js';

import illit_logo_white from '@/assets/images/illit-logo-white.png';
import illit_logo_1to2 from '@/assets/images/illit-logo-white-1to2.png';

export default {

    data() {
        return {
            isMouseOver1: false,
            illit_logo_1to2, illit_logo_white,

            recentUpdateTime: null,

            subscribers_num: null,
            isInputFilled: false,
            youtube_channel_data: [],

            inputVideoId: null,
            inputUploadedAt: null,
            inputViewCount: null,
            inputLikeCount: null,
            viewCountRanking: null,
            likeCountRanking: null,
            videoCount: null,
        };
    },

    mounted() {
        axios.get('https://litverse.io/api/youtube/illit-channel/')
        .then(response => {
            response.data


            const groupedByTimestamp = response.data.reduce((acc, item) => {
                const key = item.timestamp;
                if (!acc[key]) acc[key] = [];
                acc[key].push(item);
                return acc;
            }, {});

            const latestTimestamp = Object.keys(groupedByTimestamp).sort().pop();
            const latestData = groupedByTimestamp[latestTimestamp]

            this.latestData = latestData

            this.recentUpdateTime = dayjs(new Date(latestTimestamp)).format('YYYY/MM/DD HH:00')

            const totalViewCount = latestData.reduce((sum, item) => sum + (item.view_count || 0), 0);
            const totalLikeCount = latestData.reduce((sum, item) => sum + (item.like_count || 0), 0);
            const totalCommentCount = latestData.reduce((sum, item) => sum + (item.comment_count || 0), 0);
        

            // #1 - 배너에 기본 Overview 출력
            this.CountUpNum('subscribers', latestData["0"].subscriber_count)
            this.CountUpNum('total-views', totalViewCount)
            this.CountUpNum('total-likes', totalLikeCount)
            this.CountUpNum('total-comments', totalCommentCount)

            // #2 - 조회수, 좋아요 Top 20 출력
            var latestViewCount = []
            var latestLikeCount = []
            var latestCommentCount = []
            var latestTitle = []
            var latestVideoId = []
            for (let i = 0; i < latestData.length; i++) {
                latestViewCount.push(latestData[i].view_count)
                latestLikeCount.push(latestData[i].like_count)
                latestVideoId.push(latestData[i].video_id)
                latestCommentCount.push(latestData[i].comment_count)
                latestTitle.push(latestData[i].video_title)
            }

            //#2 - 2 조회수 선별
            const combinedViewTitle = latestTitle.map((title, index) => ({
                title,
                viewCount: latestViewCount[index],
                video_id: latestVideoId[index]
            }));
            combinedViewTitle.sort((a, b) => b.viewCount - a.viewCount);
            const combinedViewTitleTop20 = combinedViewTitle.slice(0, 20);

            const sortedViewTitlesTop20 = combinedViewTitleTop20.map(item => item.title);
            const sortedViewCountsTop20 = combinedViewTitleTop20.map(item => item.viewCount);
            const sortedViewVideoIdsTop20 = combinedViewTitleTop20.map(item => item.video_id);

            this.sortedViewVideoUrlTop20 = []
            for (let i of sortedViewVideoIdsTop20) {
                this.sortedViewVideoUrlTop20.push('https://www.youtube.com/watch?v=' + i)
            }


            const finalViewData = sortedViewCountsTop20.map((value, index) => ({
                value: value,
                url: this.sortedViewVideoUrlTop20[index]
            }));



            //#2 - 2 좋아요 선별
            const combinedLikeTitle = latestTitle.map((title, index) => ({
                title,
                likeCount: latestLikeCount[index],
                video_id: latestVideoId[index]
            }));
            combinedLikeTitle.sort((a, b) => b.likeCount - a.likeCount);
            const combinedLikeTitleTop20 = combinedLikeTitle.slice(0, 20);

            const sortedLikeTitlesTop20 = combinedLikeTitleTop20.map(item => item.title);
            const sortedLikeCountsTop20 = combinedLikeTitleTop20.map(item => item.likeCount);
            const sortedLikeVideoIdsTop20 = combinedLikeTitleTop20.map(item => item.video_id);

            this.sortedLikeVideoUrlTop20 = []
            for (let i of sortedLikeVideoIdsTop20) {
                this.sortedLikeVideoUrlTop20.push('https://www.youtube.com/watch?v=' + i)
            }

            const finalLikeData = sortedLikeCountsTop20.map((value, index) => ({
                value: value,
                url: this.sortedLikeVideoUrlTop20[index]
            }));


            this.makeBarChart('view-top20', '조회수 상위 20개', '조회수', '#fe0002',  finalViewData, sortedViewTitlesTop20)
            this.makeBarChart('like-top20', '좋아요 상위 20개', '좋아요', '#34a1fe',  finalLikeData, sortedLikeTitlesTop20)

            this.sortedViewTitles = combinedViewTitle.map(item => item.title);
            this.sortedViewCounts = combinedViewTitle.map(item => item.viewCount);
            this.sortedLikeTitles = combinedLikeTitle.map(item => item.title);
            this.sortedLikeCounts = combinedLikeTitle.map(item => item.likeCount);



            //#3 - 3 댓글 수 선별
            const combinedCommentTitle = latestTitle.map((title, index) => ({
                title,
                commentCount: latestCommentCount[index]
            }));
            combinedCommentTitle.sort((a, b) => b.likeCount - a.likeCount);

            this.sortedCommentTitles = combinedCommentTitle.map(item => item.title);
            this.sortedCommentCounts = combinedCommentTitle.map(item => item.commentCount);










            
            this.subscriber_countData = []
            this.video_countData = []
            this.xAxisData_raw = []
            this.xAxisData = []

            for (let i = 0; i < 14; i++) {
                this.timestampToFindData = dayjs(new Date(latestTimestamp) - new Date(i * 24 * 60 * 60 * 1000)).format('YYYY-MM-DDT00:00:00')
                this.xAxisData_raw.unshift(this.timestampToFindData)
                this.xAxisData.unshift(dayjs(this.timestampToFindData).format('YYYY/MM/DD'))

                if (!groupedByTimestamp[this.timestampToFindData]) {
                    this.subscriber_countData.unshift(null)
                    this.video_countData.unshift(null)
                } else {
                    this.subscriber_countData.unshift(groupedByTimestamp[this.timestampToFindData][0].subscriber_count)
                    this.video_countData.unshift(groupedByTimestamp[this.timestampToFindData].length)
                }
            }

            //구독자 수 데이터 설정
            this.subscriberDataSet = []
            this.subscriberDataSet.push(
                {
                    name: '구독자',
                    type: 'line',
                    lineStyle: {
                    width: 4,
                    },
                    symbolSize: 8,
                    triggerLineEvent: true,
                    data: this.subscriber_countData,
                    itemStyle: {
                    color: '#fe0002'
                    }
                }
                )

            this.video_countDataSet = []
            this.video_countDataSet.push(
                {
                    name: '동영상 개수',
                    type: 'line',
                    lineStyle: {
                    width: 4,
                    },
                    symbolSize: 8,
                    triggerLineEvent: true,
                    data: this.video_countData,
                    itemStyle: {
                    color: '#34a1fe'
                    }
                }
                )

            this.makeLineChart('subscriber-chart', '구독자 수 추이', this.xAxisData, this.subscriberDataSet)
            this.makeLineChart('video-count-chart', '동영상 개수 추이', this.xAxisData, this.video_countDataSet)

        })
    },

    methods: {
        makeLineChart(elementId, title, data, series) {
            var myChart = echarts.init(document.getElementById(elementId));
            window.addEventListener('resize', function() {
                myChart.resize();
            })

            myChart.setOption({
                title: {
                    text: title
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove',
                },
                xAxis: {
                    data: data,
                },
                yAxis: {
                    type: 'value',
                    scale: 'true',
                },
                series: series,
                grid: {
                    left: 80,
                    right: 0,
                },
                legend: {
                    left: 'right',
                    top: 'top',
                } 
            })
        },
        makeBarChart(elementId, title, name, color, xAxisData, yAxisData) {
            var myChart = echarts.init(document.getElementById(elementId));
            window.addEventListener('resize', function() {
                myChart.resize();
            })

            // 막대 그래프 데이터 설정
            var option = {
                title: {
                    text: title,  // 그래프 제목
                },
                tooltip: {
                    trigger: 'axis'  // 툴팁 표시 방식 (축을 기준으로 표시)
                },
                xAxis: {
                    type: 'value',  // x축은 카테고리
                      // x축에 표시될 값
                },
                yAxis: {
                    data: yAxisData,
                    inverse: true,
                    axisLabel: {
                        formatter: function(value) {
                            if (value.length > 15) {
                                return value.substring(0, 15) + '...';
                            }
                            return value;
                        }
                    }
                },
                series: [{
                    name: name,  // 데이터 시리즈 이름
                    type: 'bar',    // 그래프 종류 (막대그래프)
                    data: xAxisData,
                    itemStyle: {
                        color: color  // 막대 색상 지정
                    }
                }],
                legend: {
                    left: 'right',
                    top: 'top',
                },
                grid: {
                    left: 180,
                    right: 40,
                },
            };

            // 설정을 기반으로 그래프를 그리기
            myChart.setOption(option);

            myChart.on('click', function(params) {
                if (params.data && params.data.url) {
                    window.open(params.data.url, '_blank');
                }
            }) 
        },
        CountUpNum(id, goalNum) {
            const countUp = new CountUp(id, goalNum);
            if (!countUp.error) {
                countUp.start();
            } else {
                console.error(countUp.error);
            }
        },
        extractVideoId(url) {
            const regex = /(?:youtu\.be\/|v=|\/embed\/|\/v\/|\/shorts\/|v%3D|vi\/|v%253D)?([a-zA-Z0-9_-]{11})/;
            const match = url.match(regex);
            return match ? match[1] : null;
        },
        inputFilled(event) {
            this.isInputFilled = true
            this.inputVideoId = this.extractVideoId(event.target.value)

            const filteredInputData = this.latestData.filter(item => item.video_id === this.inputVideoId);
            this.inputVideoTitle = filteredInputData[0].video_title
            this.inputUploadedAt = filteredInputData[0].uploaded_at
            this.inputViewCount = filteredInputData[0].view_count.toLocaleString();
            this.inputLikeCount = filteredInputData[0].like_count.toLocaleString();

            this.viewCountRanking = this.sortedViewTitles.indexOf(this.inputVideoTitle) + 1
            this.likeCountRanking = this.sortedLikeTitles.indexOf(this.inputVideoTitle) + 1
            this.commentCountRanking = this.sortedCommentTitles.indexOf(this.inputVideoTitle) + 1

            this.videoCount = this.sortedViewTitles.length
        }   
    }
}
</script>