<template>
    <nav class="navbar navbar-expand-lg">
      <a href="#"><img :src=menu_icon height="40px" class="menu-icon" style="padding-left: 15px;" @click.prevent="toggleMenu"></a>
      <router-link to="/"><a href="#"><img :src=logo height="40px" class="logo"></a></router-link>
  
      <span class="ms-auto" style="padding-right: 15px;">데뷔일로부터: +{{ calculatedDebutTime_Day }}일 {{ calculatedDebutTime_Hour }}시간 {{ calculatedDebutTime_Min }}분 {{ calculatedDebutTime_Sec }}초</span>
    </nav>
  
    <div class="slide-menu" :class="{ open: isMenuOpen }">
      <br><br>
      <div style="display: grid; grid-template-rows: 75% 20%; height: 100%;">
        <div>
          <div style="padding: 30px; padding-left: 10px; border-top: lightgray solid 1px;"><h5>데뷔 1주년까지:</h5><h1 style="display: inline;">{{ calculatedDebutAnniversary }}</h1><span> 일 남음</span></div>
          <ul style="padding-inline-start: 0;">
            <li>
              <div @click="this.isMenuOpen = false"><router-link to="/" style="display: block; color: inherit; text-decoration: none;"><img width="24px" height="24px" src='@/assets/images/litverse-icon.png'><span style="padding-left: 15px;">홈</span></router-link></div>
            </li>
            <li>
              <div style="display: grid; grid-template-columns: 90% 10%;" @click="toggleSubMenu1">
                <div><a><img width="24px" height="24px" src='@/assets/images/melon-logo.png'><span style="padding-left: 15px;">음원 스트리밍 서비스</span></a></div>
                <div v-if="isSubMenuOpen1==false" style="text-align: right;"><img width="16px" src="@/assets/images/plus.png"></div>
                <div v-if="isSubMenuOpen1==true" style="text-align: right;"><img width="16px" src="@/assets/images/minus.png"></div> 
              </div>
            </li>
            <ul v-show="isSubMenuOpen1==true">
              <li><router-link to="/streaming-service/overview"><span @click="this.isMenuOpen = false">종합</span></router-link></li>
              <li><router-link to="/streaming-service/melon"><span @click="this.isMenuOpen = false">멜론</span></router-link></li>
              <li><router-link to="/streaming-service/genie"><span @click="this.isMenuOpen = false">지니</span></router-link></li>
              <li><router-link to="/streaming-service/spotify"><span @click="this.isMenuOpen = false">스포티파이</span></router-link></li>
              <li><router-link to="/streaming-service/apple-music"><span @click="this.isMenuOpen = false">애플 뮤직</span></router-link></li>
            </ul>
  
            <li>
              <div class="subMenu2" style="display: grid; grid-template-columns: 90% 10%;" @click="toggleSubMenu2">
                <div>
                  <a><img width="24px" src='@/assets/images/youtube-logo.png'><span style="padding-left: 15px;">유튜브</span></a>
                </div>
                <div v-if="isSubMenuOpen2==false" style="text-align: right;"><img width="16px" src="@/assets/images/plus.png"></div>
                <div v-if="isSubMenuOpen2==true" style="text-align: right;"><img width="16px" src="@/assets/images/minus.png"></div>
              </div>
            </li>
            <ul v-show="isSubMenuOpen2==true">
              <li><router-link to="/youtube/illit-channel"><span @click="this.isMenuOpen = false">아일릿 채널</span></router-link><a href=""></a></li>
              <li><router-link to="/youtube/musicvideo"><span @click="this.isMenuOpen = false">뮤직 비디오</span></router-link></li>
            </ul>
  
            <li>
              <div class="no-hover" style="background-color: inherit;"></div>
            </li>
  
            <!-- <li>
              <div>
                <img width="24px" height="24px" src='@/assets/images/instagram-logo.png'><span style="padding-left: 15px;">인스타그램</span>
              </div>  
            </li>
  
            <li>
              <div>
                <img width="24px" src='@/assets/images/x-logo.png'><span style="padding-left: 15px;">X</span>
              </div>
            </li>
            
            <li>
              <div>
                <a @click="toggleSubMenu5"><img width="24px" height="24px" src='@/assets/images/weverse-logo.png'><span style="padding-left: 15px;">위버스</span></a>
              </div>
            </li>
            <ul v-show="isSubMenuOpen5==true">
              <li>포스트</li>
              <li>다이렉트 메시지</li>
            </ul> -->
          </ul>
        </div>          
        <div style="text-align: center; align-self: end; background-color: white">
          <img style="z-index: 10001;" width="70%" 
          :src="illit_logo_black">
        </div>
      </div>
    </div>
  
  
    <transition name="menu-overlay-transition">
      <div v-if="isMenuOpen" :class="{ 'menu-overlay': true, active: isMenuOpen }" @click="this.isMenuOpen = false" class="menu-overlay"></div>
    </transition>
    
  
    <router-view></router-view>
    <!-- <div class="bottom-bar">
      뭐하지? ㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠㅠ
    </div> -->
  </template>
  
<style scoped>
  #app {
    font-family: NanumSquareNeo-cBd;
  }
  
  @font-face {
    font-family: NanumSquareNeo-cBd;
    src: url('../assets/fonts/NanumSquareNeo-cBd.ttf');
  }
  
  body {
    user-select: none;
  }
  img {
    -webkit-user-drag: none;
  }
  
  .navbar {
    position: fixed !important;
    top: 0;
    width: 100%;
    height: 56px;
    background-color: white;
    border-bottom: solid lightgray 1px;
    z-index: 10001;
    margin-bottom: 56px;
  }
  
  .menu-icon:hover {
    height: 44px;
    transform-origin: center;
    transition: height 0.2s;
  }
  
  .logo:hover {
    height: 42px;
    transform-origin: center;
    transition: height 0.2s;
  }
  
  .menu-icon {
    position: absolute;
    top: 28px;
    left: 30px;
    height: 40px;
    transform: translate(-50%, -50%);
    transform-origin: center;
    transition: height 0.2s;
  }
  
  .logo {
    position: absolute;
    top: 28px;
    left: 160px;
    height: 40px;
    transform: translate(-50%, -50%);
    transform-origin: center;
    transition: height 0.2s;
  }
  
  .slide-menu {
    position: fixed;
    top: 0;
    left: -331px;
    width: 320px;
    height: 100%;
    background-color: #fff;
    transition: left 0.7s ease;
    z-index: 10000;
    padding: 20px;
    box-shadow: 1px 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .slide-menu > div > div > ul > li {
    font-size: 15pt;
    /* font-weight: bolder; */
    list-style-type: none;
  }
  
  .slide-menu > div > div > ul > li > div {
    font-size: 15pt;
    /* font-weight: bolder; */
    list-style-type: none;
    padding: 12px;
    border-top: 1px solid lightgray;
  }
  
  .slide-menu > div > div > ul > li > a {
    color: inherit;
    text-decoration: none;
  }
  
  .slide-menu > div > div > ul > ul > li {
    font-size: 13pt;
    list-style-type: none;
    padding-bottom: 12px;
    padding-left: 20px;
    
  }
  
  .slide-menu > div > div > ul > ul > li > a {
    color: inherit;
    text-decoration: none;
  }
  
  .slide-menu > div > div > ul > ul > li > a:hover {
    cursor: pointer;
    transition: color 0.2s ease;
    color: gray;
  }
  
  .slide-menu > div > div > ul > li:hover {
    cursor: pointer;
    transition: background-color 0.4s ease;
    background-color: #dadada;
  }
  
  .no-hover:hover {
    cursor: default !important;
    background-color: white !important; 
  }
  
  .slide-menu.open {
    left: 0;
  }
  
  .menu-overlay {
    position: fixed; 
    height: 100%; 
    width: 100%; 
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .menu-overlay-transition-enter-active,
  .menu-overlay-transition-leave-active {
    transition: background-color 0.7s ease;
  }
  
  .menu-overlay-transition-enter-from {
    background-color: rgba(0, 0, 0, 0); /* 처음에는 투명 */
  }
  /* 요소가 나타나면 */
  .menu-overlay-transition-enter-to {
    background-color: rgba(0, 0, 0, 0.5); /* 배경이 반투명 */
  }
  
  /* 요소가 사라지기 전 */
  .menu-overlay-transition-leave-to {
    background-color: rgba(0, 0, 0, 0); /* 요소가 사라지기 전에도 투명 */
  }
  /* 요소가 사라지면 */
  .menu-overlay-transition-leave-from {
    background-color: rgba(0, 0, 0, 0.5); /* 배경이 반투명 */
  }
  
  .slide-menu-transition-enter-active, .slide-menu-transition-leave-active {
    transition: height 0.3s ease;
  }
  
  .slide-menu-transition-enter, .slide-menu-transition-leave-to /* .submenu-leave-active in <2.1.8 */ {
    height: 0;
    overflow: hidden;
  }
  
  .slide-menu-transition-enter-to, .slide-menu-transition-leave /* .submenu-leave-active in <2.1.8 */ {
    height: auto;
  }
  
  .bottom-bar {
    height: 300px;
    border-top: lightgray solid 1px;
  }
  </style>
  
  <script>
  import axios from 'axios';
  
  import logo from '@/assets/images/logo.png';
  import logo1to2 from '@/assets/images/logo-1to2.png';
  import menu_icon from '@/assets/images/menu-icon.png';
  import menu_icon1to2 from '@/assets/images/menu-icon-1to2.png';
  import illit_logo_black from '@/assets/images/illit-logo-black.png';
  import illit_logo_1to2 from '@/assets/images/illit-logo-1to2.png';
  
  export default {
    data() {
      return {
        isMouseOver1: false,
        isMouseOver2: false,
        isMouseOver3: false,
        logo, logo1to2, menu_icon, menu_icon1to2, illit_logo_1to2, illit_logo_black,
  
        isMenuOpen: false,
        isSubMenuOpen1: false,
        isSubMenuOpen2: false,
        isSubMenuOpen3: false,
        isSubMenuOpen4: false,
        isSubMenuOpen5: false,
  
        calculatedDebutTime: null,
        calculatedDebutTime_Day: null,
        calculatedDebutTime_Hour: null,
        calculatedDebutTime_Min: null,
        calculatedDebutTime_Sec: null,
  
      };
    },
  
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      toggleSubMenu1() {
        this.isSubMenuOpen1 = !this.isSubMenuOpen1
      },
      toggleSubMenu2() {
        this.isSubMenuOpen2 = !this.isSubMenuOpen2
      },
      toggleSubMenu3() {
        this.isSubMenuOpen3 = !this.isSubMenuOpen3
      },
      toggleSubMenu4() {
        this.isSubMenuOpen4 = !this.isSubMenuOpen4
      },
      toggleSubMenu5() {
        this.isSubMenuOpen5 = !this.isSubMenuOpen5
      },
      calculateDebutTime() {
        const currentDate = new Date(this.nowDateTime);
        const debutDate = new Date(2024, 2, 25, 19);
  
        this.calculatedDebutTime = currentDate - debutDate
      }
    },
  
    mounted() {
      axios.get('https://litverse.io/api/etcapi/currenttime/')
      .then(response => {
        this.nowDateTime = response.data;
        this.nowDateOnly = new Date(this.nowDateTime).toLocaleDateString();
      })
      .then(() => {
        this.calculateDebutTime();
        this.calculatedDebutTime_Day = Math.floor(this.calculatedDebutTime / 86400000);
        this.calculatedDebutTime_Hour = Math.floor((this.calculatedDebutTime % 86400000) / 3600000);
        this.calculatedDebutTime_Min = Math.floor(((this.calculatedDebutTime % 86400000) % 3600000) / 60000)
        this.calculatedDebutTime_Sec = Math.floor((((this.calculatedDebutTime % 86400000) % 3600000) % 60000) / 1000);
      })
      .then(() => {
        setInterval(() => {
          this.calculatedDebutTime += 1000;
  
          this.calculatedDebutTime_Day = Math.floor(this.calculatedDebutTime / 86400000);
          this.calculatedDebutTime_Hour = Math.floor((this.calculatedDebutTime % 86400000) / 3600000);
          this.calculatedDebutTime_Min = Math.floor(((this.calculatedDebutTime % 86400000) % 3600000) / 60000)
          this.calculatedDebutTime_Sec = Math.floor((((this.calculatedDebutTime % 86400000) % 3600000) % 60000) / 1000);
  
          this.calculatedDebutAnniversary = Math.floor((new Date(2025, 2, 25) - new Date(this.nowDateOnly)) / 86400000);
        }, 1000);
      })
    }
  };
  </script>
  
  