import { createApp } from 'vue'
import App from './App.vue'


// import { CountUp } from 'countup.js';

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import desktopRouter from './router/desktopRouter'
import mobileRouter from './router/mobileRouter'

const isMobile = window.location.host === 'm.litverse.io' || window.location.host === 'm.localhost:8081';
const router = isMobile ? mobileRouter : desktopRouter;


import * as echarts from 'echarts';

function renderBarChart(elementId, data, series, fontSize) {
    var myChart = echarts.init(document.getElementById(elementId));
    window.addEventListener('resize', function() {
        myChart.resize();
    })

    myChart.setOption({
        title: {
        },
        tooltip: {
            trigger: 'axis',
            triggerOn: 'click',
        },
        xAxis: {
            data: data,
            axisLabel: {
              textStyle: {
                fontSize: fontSize,
              }
            }
        },
        yAxis: {
            type: 'value',
            scale: 'true',
            inverse: true,
            max: 100,
            min: 1,
        },
        series: series,
        grid: {
            left: 22,
            right: 0,
            top: 40,
            bottom: 20,
        },
        legend: {
            left: 'right',
            top: '0',
        } 
    })  
}

const app = createApp(App);

app.mixin({
    methods: {
        renderBarChart
    }
})


app.use(router)
app.mount('#app');
