<template>
  <component :is="currentApp" />
</template>

<script>
import DesktopApp from './views/DesktopApp.vue';
import MobileApp from './views/MobileApp.vue';

export default {
  name: 'App',
  computed: {
    currentApp() {
      return window.location.host === 'm.litverse.io' || window.location.host === 'm.localhost:8081' ? MobileApp : DesktopApp;
    },
  },
};
</script>