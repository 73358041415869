<template>
    <div style="width: 100%; display: grid; place-items: center;">
        <div style="height: 56px;"></div>
        <div class="melon-overview">
            <div style=" display: grid; place-items: center; text-align: center;"><a href="https://www.melon.com/artist/timeline.htm?artistId=3743525" target="_blank"><img width="200px" src="@/assets/images/melon_fulllogo.png"></a></div>
            <div style="border-right: lightgray solid 1px; border-left: lightgray solid 1px; "><div><h4>현재 순위</h4><h1 style="display: inline;" id="realtime-ranking"></h1><span> 위</span></div></div>
            <div style="border-right: lightgray solid 1px; "><div><h4>24시간 내 최고 순위</h4><h1 style="display: inline;" id="peak-in-24hours"></h1><span> 위</span></div></div>
            <div style=""><div><h4>2주 내 최고 순위</h4><h1 style="display: inline;" id="peak-in-2weeks"></h1><span> 위</span></div></div>
        </div>

        <div class="contents">
          <div style="border-bottom: lightgray solid 1px; padding: 17px; display: grid; grid-template-columns: 80% 20%;"><div><h2 style="display: inline;">실시간 차트 추이</h2></div><div style="text-align: right; color: gray;"><span>ⓘ 최근 갱신: {{ this.latestRealtimeRefresh }}</span></div></div>
          <div style="padding-top: 20px; width: 100%; height: 500px;" id="realtime-chart"></div>
          <div style="border-bottom: lightgray solid 1px; padding: 17px; display: grid; grid-template-columns: 80% 20%;"><div><h2 style="display: inline;">일간 차트 추이</h2></div><div style="text-align: right; color: gray;"><span>ⓘ 최근 갱신: {{ this.latestDailyRefresh }}</span></div></div>
          <div style="padding-top: 20px; width: 100%; height: 500px;" id="daily-chart"></div>
        </div>

    </div>
</template>

<style>
.melon-overview {
    display: grid; 
    place-items: center;
    width: 85%; 
    grid-template-columns: 25% 25% 25% 25%;
    padding-top: 80px;
    padding-bottom: 80px;
}

.melon-overview > div {
  display: grid;
  place-items: center;
  text-align: left;
  width: 100%;
}


.contents {
  width: 85%;
}

#chart {
  width: 100%;
  height: 500px; /* ECharts 차트가 보이도록 크기를 지정 */
}
</style>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import { CountUp } from 'countup.js';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      timestampIn24Hours: [],
      timestampIn24Hours_ui: [],
      rankingData: [],
      dataSets: [],
      dailyDataSets: [],
      dailyRankingData: [],
      realtimeDataSets: [],
      realtimeRankingData: [],

      latestDailyRefresh: null,
      latestRealtimeRefresh: null,
      chartInCount: null,
    }

  },
  mounted() {
    // 일간 차트 현황 불러오기
    axios.get('https://litverse.io/api/streaming-service/melon/daily/')
    .then((response1) => {
      const dailyData = response1.data;
      const dailyData_Title = [...new Set(dailyData.map(item => item.title))]

      const rankingIn2Weeks = dailyData.map(item => item.ranking);
      const peakRankingIn2Weeks = Math.min(...rankingIn2Weeks);
      this.CountUpNum('peak-in-2weeks', peakRankingIn2Weeks)

      this.latestDailyRefresh = dayjs(new Date(dailyData[dailyData.length - 1].timestamp)).add(1, 'day').format('YYYY/MM/DD 23:30')
      
      for (let i of dailyData_Title) {
        var xAxisData = []
        this.dailyRankingData = []

        for (let j=0; j < 14; j++) {
          var timestampToFindData = dayjs(new Date(dailyData[dailyData.length - 1].timestamp) - (j * 24 * 60 * 60 * 1000)).format('YYYY-MM-DDT00:00:00')
          xAxisData.unshift(dayjs(new Date(dailyData[dailyData.length - 1].timestamp) - new Date((j * 24 * 60 * 60 * 1000))).format('MM/DD'))
          var filteredData = dailyData.filter(item => item.timestamp === timestampToFindData && item.title === i);
          if (filteredData.length === 0) {
            this.dailyRankingData.unshift(null)
          } else {
            this.dailyRankingData.unshift(filteredData["0"].ranking)
          }
        }
        if (i === 'Magnetic' || i === 'Lucky Girl Syndrome') {
          this.chartColor = '#505d53'
        } else if (i === 'Cherish (My Love)' || i === 'Tick-Tack') {
          this.chartColor = '#9d9c6a'
        } else {
          this.chartColor = 'black'
        }
        this.dailyDataSets.push(
          {
            name: i,
            type: 'line',
            lineStyle: {
              width: 4,
            },
            symbolSize: 8,
            triggerLineEvent: true,
            data: Object.values(this.dailyRankingData),
            itemStyle: {
              color: this.chartColor
            }
          }
        )
      }
      this.makeChart('daily-chart', xAxisData, this.dailyDataSets)
    })

    // 실시간 차트 현황 불러오기
    axios.get('https://litverse.io/api/streaming-service/melon/realtime/')
    .then((response2) => {
      const realtimeData = response2.data;
      const realtimeData_Title = [...new Set(realtimeData.map(item => item.title))]

      this.latestRealtimeRefresh = dayjs(new Date(realtimeData[realtimeData.length - 1].timestamp)).format('YYYY/MM/DD HH:00')

      const latestData = realtimeData.filter(item => item.timestamp === realtimeData[realtimeData.length - 1].timestamp);
      const topRankingItem = latestData.reduce((best, item) => {
        return item.ranking < best.ranking ? item:best;
      }, latestData[0])

      const rankingIn24Hours = realtimeData.map(item => item.ranking);
      const peakRankingIn24Hours = Math.min(...rankingIn24Hours);

      this.CountUpNum('realtime-ranking', topRankingItem.ranking)
      this.CountUpNum('peak-in-24hours', peakRankingIn24Hours)

      for (let i of realtimeData_Title) {
        var xAxisData = []
        this.realtimeRankingData = []

        for (let j=0; j < 25; j++) {
          var timestampToFindData = dayjs(new Date(realtimeData[realtimeData.length - 1].timestamp) - (j * 60 * 60 * 1000)).format('YYYY-MM-DDTHH:00:00')
          xAxisData.unshift(dayjs(new Date(realtimeData[realtimeData.length - 1].timestamp) - new Date((j * 60 * 60 * 1000))).format('HH:00'))
          var filteredData = realtimeData.filter(item => item.timestamp === timestampToFindData && item.title === i);
          if (filteredData.length === 0) {
            this.realtimeRankingData.unshift(null)
          } else {
            this.realtimeRankingData.unshift(filteredData["0"].ranking)
          }
        }
        if (i === 'Magnetic' || i === 'Lucky Girl Syndrome') {
          this.chartColor = '#505d53'
        } else if (i === 'Cherish (My Love)' || i === 'Tick-Tack') {
          this.chartColor = '#9d9c6a'
        } else {
          this.chartColor = 'black'
        }
        this.realtimeDataSets.push(
          {
            name: i,
            type: 'line',
            lineStyle: {
              width: 4,
            },
            symbolSize: 8,
            triggerLineEvent: true,
            data: Object.values(this.realtimeRankingData),
            itemStyle: {
              color: this.chartColor
            }
          }
        )
      }
      this.makeChart('realtime-chart', xAxisData, this.realtimeDataSets)
    })
  },
  methods: {
    makeChart(elementId, data, series) {
      var myChart = echarts.init(document.getElementById(elementId));
      window.addEventListener('resize', function() {
        myChart.resize();
      })

      myChart.setOption({
        title: {
        },
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove',
        },
        xAxis: {
          data: data
        },
        yAxis: {
          max: 1,
          min: 100,
          inverse: true,
        },
        series: series,
        grid: {
          left: 30,
          right: 0,
        },
        legend: {
          left: 'right',
          top: 'top',
        }
      })      
    },
    CountUpNum(id, goalNum) {
      const countUp = new CountUp(id, goalNum);
      if (!countUp.error) {
          countUp.start();
      } else {
          console.error(countUp.error);
      }
    },
  }
}
</script>