<template>
    <nav class="navbar navbar-expand-lg">
      <a href="#"><img src='@/assets/images/menu-icon.png' height="40px" class="menu-icon" style="padding-left: 15px;" @click.prevent="toggleMenu"></a>
      <!-- <router-link to="/"><a href="#"><img :src=logo height="40px" class="logo"></a></router-link> -->
  
      <span class="ms-auto" style="padding-right: 15px; font-size: 10.5pt;">데뷔일로부터: +{{ calculatedDebutTime_Day }}일 {{ calculatedDebutTime_Hour }}시간 {{ calculatedDebutTime_Min }}분 {{ calculatedDebutTime_Sec }}초</span>
    </nav>
    <div style="height: 56px;"></div>

    <div class="slide-menu" :class="{ open: isMenuOpen }">
        <div style="width: 100%;">
          <router-link to="/" @click="this.isMenuOpen = false"><img src='@/assets/images/logo.png' height="70px" style="padding: 15px;"></router-link>
       </div>
        <div style="border-bottom: lightgray solid 1px;"></div>
        <div style="padding: 20px; padding-left: 30px;"><p style="margin-bottom: 5px;">데뷔 1주년까지:</p><h1 style="display: inline;">{{ calculatedDebutAnniversary }}</h1><span style="font-size: 11pt;"> 일 남음</span></div>

        <ul style="padding-inline-start: 0;">
            <li>
              <div @click="this.isMenuOpen = false"><router-link to="/" style="display: block; color: inherit; text-decoration: none;"><img width="24px" height="24px" src='@/assets/images/litverse-icon.png'><span style="padding-left: 15px;">홈</span></router-link></div>
            </li>
            <li>
                <div style="display: grid; grid-template-columns: 90% 10%;" @click="toggleSubMenu1">
                    <div><a><img width="24px" height="24px" src='@/assets/images/melon-logo.png'><span style="padding-left: 15px;">음원 스트리밍 서비스</span></a></div>
                    <div v-if="isSubMenuOpen1==false" style="text-align: right;"><img width="16px" src="@/assets/images/plus.png"></div>
                    <div v-if="isSubMenuOpen1==true" style="text-align: right;"><img width="16px" src="@/assets/images/minus.png"></div> 
                </div>
            </li>
            <ul v-show="isSubMenuOpen1==true">
              <li><router-link to="/streaming-service/overview"><span @click="this.isMenuOpen = false">종합</span></router-link></li>
              <li><router-link to="/streaming-service/melon"><span @click="this.isMenuOpen = false">멜론</span></router-link></li>
              <li><router-link to="/streaming-service/genie"><span @click="this.isMenuOpen = false">지니</span></router-link></li>
              <li><router-link to="/streaming-service/spotify"><span @click="this.isMenuOpen = false">스포티파이</span></router-link></li>
              <li><router-link to="/streaming-service/apple-music"><span @click="this.isMenuOpen = false">애플 뮤직</span></router-link></li>
            </ul>
  
            <li>
              <div class="subMenu2" style="display: grid; grid-template-columns: 90% 10%;" @click="toggleSubMenu2">
                <div>
                  <a><img width="24px" src='@/assets/images/youtube-logo.png'><span style="padding-left: 15px;">유튜브</span></a>
                </div>
                    <div v-if="isSubMenuOpen2==false" style="text-align: right;"><img width="16px" src="@/assets/images/plus.png"></div>
                    <div v-if="isSubMenuOpen2==true" style="text-align: right;"><img width="16px" src="@/assets/images/minus.png"></div>
                </div>
            </li>
            <ul v-show="isSubMenuOpen2==true">
              <li><router-link to="/youtube/illit-channel"><span @click="this.isMenuOpen = false">아일릿 채널</span></router-link><a href=""></a></li>
              <li><router-link to="/youtube/musicvideo"><span @click="this.isMenuOpen = false">뮤직 비디오</span></router-link></li>
            </ul>
            <li></li>

        </ul>
    </div>

    <transition name="menu-overlay-transition">
      <div v-if="isMenuOpen" :class="{ 'menu-overlay': true, active: isMenuOpen }" @click="this.isMenuOpen = false" class="menu-overlay"></div>
    </transition>

    <router-view />

</template>
  

  
<style>
#app {
font-family: NanumSquareNeo-cBd;
}

@font-face {
font-family: NanumSquareNeo-cBd;
src: url('../assets/fonts/NanumSquareNeo-cBd.ttf');
}

.navbar {
    position: fixed !important;
    top: 0;
    width: 100%;
    height: 56px;
    background-color: white;
    border-bottom: solid lightgray 1px;
    z-index: 10001;
    margin-bottom: 56px;
}

.slide-menu {
    position: fixed;
    background-color: white;
    border-right: lightgray solid 1px;
    left: -70%;
    width: 70%; 
    height: 100%;
    transition: left 0.7s ease;
    z-index: 10000;
}

.slide-menu.open {
  left: 0; /* 메뉴가 열리면 화면에 나타남 */
}

.slide-menu > ul > li {
    padding: 15px;
    list-style-type: none;
    border-top: lightgray solid 1px;
    background-color: rgb(255, 255, 255);

    transition: background-color 0.2s ease;
}

.slide-menu > ul > li.clicked {
  background-color: rgba(0, 0, 0, 0.1);

}

.slide-menu > ul > ul > li {
    font-size: 10pt;
    padding-bottom: 5px;
    padding-left: 22px;
    list-style-type: none;
}

.slide-menu > ul > ul {
  padding-bottom: 15px;
}

.slide-menu > ul > ul > li > a {
    text-decoration: none;
    color: inherit;
}

.slide-menu > ul > ul > li > a:active {
    text-decoration: none;
    color: lightgray;
}

.menu-overlay {
    position: fixed; 
    height: 100%; 
    width: 100%; 
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}
  
.menu-overlay-transition-enter-active,
.menu-overlay-transition-leave-active {
    transition: background-color 0.7s ease;
}
  
.menu-overlay-transition-enter-from {
    background-color: rgba(0, 0, 0, 0); /* 처음에는 투명 */
}
  /* 요소가 나타나면 */
.menu-overlay-transition-enter-to {
    background-color: rgba(0, 0, 0, 0.5); /* 배경이 반투명 */
}
  
  /* 요소가 사라지기 전 */
.menu-overlay-transition-leave-to {
    background-color: rgba(0, 0, 0, 0); /* 요소가 사라지기 전에도 투명 */
}
  /* 요소가 사라지면 */
.menu-overlay-transition-leave-from {
    background-color: rgba(0, 0, 0, 0.5); /* 배경이 반투명 */
}
  
.slide-menu-transition-enter-active, .slide-menu-transition-leave-active {
    transition: height 0.3s ease;
}
  
.slide-menu-transition-enter, .slide-menu-transition-leave-to /* .submenu-leave-active in <2.1.8 */ {
    height: 0;
    overflow: hidden;
}
  
.slide-menu-transition-enter-to, .slide-menu-transition-leave /* .submenu-leave-active in <2.1.8 */ {
    height: auto;
}
</style>

<script>
import axios from 'axios';

export default {
  name: 'MobileApp',
  data() {
    return {
        isMenuOpen: false,

        calculatedDebutTime: null,
        calculatedDebutTime_Day: null,
        calculatedDebutTime_Hour: null,
        calculatedDebutTime_Min: null,
        calculatedDebutTime_Sec: null,

        calculatedDebutAnniversary: null,

        isSubMenuOpen1: false,
        isSubMenuOpen2: false,
        isSubMenuOpen3: false,
        isSubMenuOpen4: false,
        isSubMenuOpen5: false,
    };
  },
  methods: {
    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    },
    toggleSubMenu1() {
        this.isSubMenuOpen1 = !this.isSubMenuOpen1
    },
    toggleSubMenu2() {
    this.isSubMenuOpen2 = !this.isSubMenuOpen2
    },
    toggleSubMenu3() {
    this.isSubMenuOpen3 = !this.isSubMenuOpen3
    },
    toggleSubMenu4() {
    this.isSubMenuOpen4 = !this.isSubMenuOpen4
    },
    toggleSubMenu5() {
    this.isSubMenuOpen5 = !this.isSubMenuOpen5
    },
    calculateDebutTime() {
        const currentDate = new Date(this.nowDateTime);
        const debutDate = new Date(2024, 2, 25, 19);
  
        this.calculatedDebutTime = currentDate - debutDate
    },
  },
  mounted() {
      axios.get('https://litverse.io/api/etcapi/currenttime/')
      .then(response => {
        this.nowDateTime = response.data;
        this.nowDateOnly = new Date(this.nowDateTime).toLocaleDateString();
      })
      .then(() => {
        this.calculateDebutTime();
        this.calculatedDebutTime_Day = Math.floor(this.calculatedDebutTime / 86400000);
        this.calculatedDebutTime_Hour = Math.floor((this.calculatedDebutTime % 86400000) / 3600000);
        this.calculatedDebutTime_Min = Math.floor(((this.calculatedDebutTime % 86400000) % 3600000) / 60000)
        this.calculatedDebutTime_Sec = Math.floor((((this.calculatedDebutTime % 86400000) % 3600000) % 60000) / 1000);
      })
      .then(() => {
        setInterval(() => {
          this.calculatedDebutTime += 1000;
  
          this.calculatedDebutTime_Day = Math.floor(this.calculatedDebutTime / 86400000);
          this.calculatedDebutTime_Hour = Math.floor((this.calculatedDebutTime % 86400000) / 3600000);
          this.calculatedDebutTime_Min = Math.floor(((this.calculatedDebutTime % 86400000) % 3600000) / 60000)
          this.calculatedDebutTime_Sec = Math.floor((((this.calculatedDebutTime % 86400000) % 3600000) % 60000) / 1000);
  
          this.calculatedDebutAnniversary = Math.floor((new Date(2025, 2, 25) - new Date(this.nowDateOnly)) / 86400000);
        }, 1000);
      })
      document.querySelectorAll('.slide-menu > ul > li').forEach(item => {
        item.addEventListener('mousedown', () => {
          item.classList.add('clicked'); // 클릭 시 클래스 추가
        });
    
        item.addEventListener('mouseup', () => {
          setTimeout(() => {
            item.classList.remove('clicked'); // 트랜지션이 끝날 때 클래스 제거
          }, 200); // 트랜지션 시간 후 클래스 제거
        });
      });
    }
};
</script>