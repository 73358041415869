<template>
    <div style="height: 136px;"></div>
    <div class="mv-overview" style="display: grid; place-items: center;">
        <div style="width: 95%; display: grid; grid-template-columns: 50% 50%; border-bottom: lightgray solid 1px; padding-bottom: 25px; margin-bottom: 48px;">
            <div style="padding: 0;"><h2>{{ this.video_title }}: 자세히 보기</h2></div>
            <div style="display: flex; align-items: center; justify-content: right; padding: 0;"><span style="color: gray; ">  ⓘ 최근 갱신: {{ this.recentUpdateTime }}</span></div>
        </div>            
        
        <div style="display: grid; grid-template-columns: 50% 50%; width: 95%; padding-bottom: 48px;">
            <div style="border-right: lightgray solid 1px; display: flex; align-items: center; justify-content: right;"><iframe width="640" height="360" v-bind:src="`https://www.youtube.com/embed/${id}?si=KZplLVgVVEwgLBGo`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>
            <div>
                <h4>{{ this.video_title }}</h4>
                <span>Video_Id: </span><span>{{ this.video_id }}</span><br>
                <span>Uploaded_At: </span><span>{{ this.uploaded_at }}</span><br><br>
                <div style="display: grid; grid-template-columns: 33% 33% 33%;">
                    <div>
                        <h5>조회수</h5><h2 style="display: inline;">{{ this.view_countComma }}</h2><span> 회</span><br><br>
                        <h5>좋아요</h5><h2 style="display: inline;">{{ this.like_countComma }}</h2><span> 개</span><br><br>
                        <h5>댓글</h5><h2 style="display: inline;">{{ this.comment_countComma }}</h2><span> 개</span>
                    </div>
                    <div>
                        <h5>조회수 순위</h5><h2 style="display: inline;">{{ this.viewCountRanking }}</h2><span> / {{ this.videoCount }} 위</span><br><br>
                        <h5>좋아요 순위</h5><h2 style="display: inline;">{{ this.likeCountRanking }}</h2><span> / {{ this.videoCount }} 위</span><br><br>
                        <h5>댓글 순위</h5><h2 style="display: inline;">{{ this.commentCountRanking }}</h2><span> / {{ this.videoCount }} 위</span>                       
                    </div>
                    <div>
                        <h5>조회수 비중</h5><h2 style="display: inline;">{{ this.viewProportionInt }}</h2><h5 style="display: inline;">{{ this.viewProportionFractical }}</h5><span> %</span><br><br>
                        <h5>좋아요 비중</h5><h2 style="display: inline;">{{ this.likeProportionInt }}</h2><h5 style="display: inline;">{{ this.likeProportionFractical }}</h5><span> %</span><br><br>
                        <h5>댓글 비중</h5><h2 style="display: inline;">{{ this.commentProportionInt }}</h2><h5 style="display: inline;">{{ this.commentProportionFractical }}</h5><span> %</span>                             
                    </div>
                </div>

            </div>
        </div>
        <div class="chart-div">
            <div id="view-chart"></div>
            <div id="like-chart"></div>
            <div id="comment-chart"></div>
        </div>    
    </div>

</template>

<style>
body {
  user-select: text;
}

.mv-overview > div > div {
    padding: 50px;
}

.chart-div {
    width: 100%;
    display: grid;
    place-items: center;
}

.chart-div > div {
    padding: 50px;
    border-top: lightgray solid 1px;
    width: 95%;
    height: 500px;
}
</style>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import axios from 'axios';

export default {
    props: ['id'],
    data() {
        return {
            video_id: this.id,
            illitChannel_json: null,
            video_title: null,
            uploaded_at: null,
            view_count: null,
            like_count: null,
            comment_count: null,
            recentUpdateTime: null,

            viewProportion: null,
            likeProportion: null,
            commentProportion: null,
        }
    },
    mounted() {
        axios.all([
            axios.get(`https://litverse.io/api/youtube/illit-channel/${this.video_id}/`),
            axios.get('https://litverse.io/api/youtube/illit-channel/')            
        ])
        .then(axios.spread((response1, response2) => {
            this.illitChannel_json = response1.data;

            this.video_title = this.illitChannel_json[0].video_title;
            this.view_count = this.illitChannel_json[this.illitChannel_json.length - 1].view_count
            this.like_count = this.illitChannel_json[this.illitChannel_json.length - 1].like_count
            this.comment_count = this.illitChannel_json[this.illitChannel_json.length - 1].comment_count
            this.uploaded_at = this.illitChannel_json[0].uploaded_at;

            this.view_countComma = this.view_count.toLocaleString();
            this.like_countComma = this.like_count.toLocaleString();
            this.comment_countComma = this.comment_count.toLocaleString();

            this.recentUpdateTime = dayjs(new Date(this.illitChannel_json[this.illitChannel_json.length - 1].timestamp)).format('YYYY/MM/DD HH:00');





            const groupedByTimestamp = response2.data.reduce((acc, item) => {
                const key = item.timestamp;
                if (!acc[key]) acc[key] = [];
                acc[key].push(item);
                return acc;
            }, {});

            const latestTimestamp = Object.keys(groupedByTimestamp).sort().pop();
            const latestData = groupedByTimestamp[latestTimestamp]

            const totalViewCount = latestData.reduce((sum, item) => sum + (item.view_count || 0), 0);
            const totalLikeCount = latestData.reduce((sum, item) => sum + (item.like_count || 0), 0);
            const totalCommentCount = latestData.reduce((sum, item) => sum + (item.comment_count || 0), 0);

            this.viewProportion = (this.view_count / totalViewCount) * 100
            this.likeProportion = (this.like_count / totalLikeCount) * 100
            this.commentProportion = (this.comment_count / totalCommentCount) * 100

            this.viewProportionInt = Math.trunc(this.viewProportion);
            this.likeProportionInt = Math.trunc(this.likeProportion);
            this.commentProportionInt = Math.trunc(this.commentProportion);

            this.viewProportionFractical = this.viewProportion.toFixed(4)
            this.likeProportionFractical = this.likeProportion.toFixed(4)
            this.commentProportionFractical = this.commentProportion.toFixed(4)

            this.viewProportionFractical = this.viewProportionFractical.substring(this.viewProportionFractical.length - 5, this.viewProportionFractical.length)
            this.likeProportionFractical = this.likeProportionFractical.substring(this.likeProportionFractical.length - 5, this.likeProportionFractical.length)
            this.commentProportionFractical = this.commentProportionFractical.substring(this.commentProportionFractical.length - 5, this.commentProportionFractical.length)


            var latestViewCount = []
            var latestLikeCount = []
            var latestCommentCount = []
            var latestTitle = []
            for (let i = 0; i < latestData.length; i++) {
                latestViewCount.push(latestData[i].view_count)
                latestLikeCount.push(latestData[i].like_count)
                latestCommentCount.push(latestData[i].comment_count)
                latestTitle.push(latestData[i].video_title)
            }

            //#2 - 2 조회수 선별
            const combinedViewTitle = latestTitle.map((title, index) => ({
                title,
                viewCount: latestViewCount[index]
            }));
            combinedViewTitle.sort((a, b) => b.viewCount - a.viewCount);

            const sortedViewTitles = combinedViewTitle.map(item => item.title);

            //#2 - 2 좋아요 선별
            const combinedLikeTitle = latestTitle.map((title, index) => ({
                title,
                likeCount: latestLikeCount[index]
            }));
            combinedLikeTitle.sort((a, b) => b.likeCount - a.likeCount);

            const sortedLikeTitles = combinedLikeTitle.map(item => item.title);

            //#2 - 3 댓글 선별
            const combinedCommentTitle = latestTitle.map((title, index) => ({
                title,
                commentCount: latestCommentCount[index]
            }));
            combinedCommentTitle.sort((a, b) => b.commentCount - a.commentCount);

            const sortedCommentTitles = combinedCommentTitle.map(item => item.title);

            this.viewCountRanking = sortedViewTitles.indexOf(this.video_title) + 1
            this.likeCountRanking = sortedLikeTitles.indexOf(this.video_title) + 1
            this.commentCountRanking = sortedCommentTitles.indexOf(this.video_title) + 1


            this.videoCount = latestData.length
        }))
        
        .then(() => {
            this.filteredData = []
            this.xAxisData_raw = []
            this.view_countData = []
            this.like_countData = []
            this.comment_countData = []
            this.xAxisData = []
            for (let i = 0; i < 14; i++) {
                this.timestampToFindData = dayjs(new Date(this.illitChannel_json[this.illitChannel_json.length - 1].timestamp) - new Date(i * 24 * 60 * 60 * 1000)).format('YYYY-MM-DDT00:00:00')
                this.xAxisData_raw.unshift(this.timestampToFindData)
                this.xAxisData.unshift(dayjs(this.timestampToFindData).format('YYYY/MM/DD'))
                var filteredData = this.illitChannel_json.filter(item => item.timestamp === this.timestampToFindData);
                if (filteredData.length === 0) {
                    this.view_countData.unshift(null)
                    this.like_countData.unshift(null)
                    this.comment_countData.unshift(null)
                } else {
                    this.view_countData.unshift(filteredData["0"].view_count)
                    this.like_countData.unshift(filteredData["0"].like_count)
                    this.comment_countData.unshift(filteredData["0"].comment_count)
                }
            }
            
            // if (i === 'Magnetic') {
            // this.chartColor = '#505d53'
            // } else if (i === 'Cherish (My Love)') {
            // this.chartColor = '#9d9c6a'
            // } else {
            // this.chartColor = 'black'
            // }
            this.view_countSets = []
            this.like_countSets = []
            this.comment_countSets = []
            for (const [i, j, k] of [[this.view_countSets, this.view_countData, '조회수'], [this.like_countSets,  this.like_countData, '좋아요'], [ this.comment_countSets,  this.comment_countData, '댓글']]) {
                if (k === '조회수') {
                    this.chartColor = '#fe0002'
                }
                if (k === '좋아요') {
                    this.chartColor = '#34a1fe'
                }
                if (k === '댓글') {
                    this.chartColor = '#808080'
                }
                i.push(
                {
                    name: k,
                    type: 'line',
                    lineStyle: {
                    width: 4,
                    },
                    symbolSize: 8,
                    triggerLineEvent: true,
                    data: Object.values(j),
                    itemStyle: {
                    color: this.chartColor
                    }
                }
                )
            }
            this.makeChart('view-chart', '조회수 추이' , this.xAxisData, this.view_countSets["0"])
            this.makeChart('like-chart', '좋아요 수 추이' ,this.xAxisData, this.like_countSets["0"])
            this.makeChart('comment-chart','댓글 수 추이' , this.xAxisData, this.comment_countSets["0"])
        })
    },
    methods: {
        makeChart(elementId, title, data, series) {
            var myChart = echarts.init(document.getElementById(elementId));
            window.addEventListener('resize', function() {
                myChart.resize();
            })

            myChart.setOption({
                title: {
                    text: title
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove',
                },
                xAxis: {
                    data: data,
                },
                yAxis: {
                    type: 'value',
                    scale: 'true'
                },
                series: series,
                grid: {
                left: 80,
                right: 0,
                },
                legend: {
                left: 'right',
                top: 'top',
                } 
            })
        }
    }
}
</script>