<template>
    <div style="height: 56px;"></div>
    <video class="music-video" autoplay muted loop  width="100%">
      <source src="@/assets/videos/desktophome_video.mp4" type="video/mp4">
    </video>
    <div class="home-content">
      <img src="@/assets/images/illit-logo-white.png" class="illit-logo">
      <br><br>
      <div class="home-content2">
        <div style="text-align: right; padding: 30px;">
          <h3>조회수</h3>
          <t><h1 id="views" style="display: inline;"></h1> 회</t>
        </div>
        <div style="text-align: center; padding: 30px; border-left: 1px solid rgba(255, 255, 255, 0.5); border-right: 1px solid rgba(255, 255, 255, 0.5);">
          <h3>좋아요</h3>
          <t><h1 id="likes" style="display: inline;"></h1> 개</t>
        </div>
        <div style="text-align: left; padding: 30px;">
          <h3>멜론</h3>
          <t><h1 id="melon" style="display: inline;"></h1> 위</t><br><br><t style="color: gray;">  ⓘ 최근 갱신: {{ this.recentUpdateTime }}</t>
        </div>
      </div>      
    </div>
</template>
  
<script>
import { CountUp } from 'countup.js';
import axios from 'axios';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      recentUpdateTime: null,
    };
  },

  methods: {
    CountUpNum(id, goalNum) {
      const countUp = new CountUp(id, goalNum);
      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }
    }
  },
  mounted() {
    axios.get('https://litverse.io/api/youtube/latest-cherish-info/')
    .then(response => {
      this.latestInfo = response.data;
      this.recentUpdateTime = dayjs(new Date(this.latestInfo[0].timestamp)).format('YYYY/MM/DD HH:00')
    })
    .then(() => {
      this.CountUpNum('views', this.latestInfo[0].view_count)
      this.CountUpNum('likes', this.latestInfo[0].like_count)
    })
    axios.get('https://litverse.io/api/streaming-service/melon/latest-cherish-chart/')
    .then(response => {
      this.latestInfo_melon = response.data;
      this.CountUpNum('melon', this.latestInfo_melon.ranking)
    })
  }
}
</script>

<style>


.music-video {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: -1;
}

.home-content {
  display: grid;
  place-items: center;
  width: 100%;

  position: absolute;
  top: 20%;
  

  z-index: 11;
}

.home-content2 {
  position: relative;
  display: grid;
  width: 1100px;
  grid-template-columns: 400px 300px 400px; 
  grid-template-rows: 100%;
}

.home-content2 > div > t {
  color: white;
}

.home-content2 > div > h3 {
  color: white;
}

.illit-logo {
  opacity: 0.85;
  /* height: 45vw; */
  width: 45vw;
}
</style>