<template>
  <div style="height: 56px;"></div>
  <div style="width: 100%; display: grid; place-items: center;">
      <div class="melon-overview">
          <div style="display: grid; place-items: center; text-align: center;"><a href="https://open.spotify.com/artist/36cgvBn0aadzOijnjjwqMN" target="_blank"><img width="200px" src="@/assets/images/spotify_fulllogo.png"></a></div>
          <div style="border-right: lightgray solid 1px; border-left: lightgray solid 1px;"><div><h4>현재 순위</h4><h1 style="display: inline;" id="realtime-ranking"></h1><span> 위</span></div></div>
          <div style="border-right: lightgray solid 1px; "><div><h4>2주 내 최고 순위</h4><h1 style="display: inline;" id="peak-in-2weeks"></h1><span> 위</span></div></div>
          <div style=""><div><h4>차트인 곡 개수</h4><h1 style="display: inline;" id="chart-in-count"></h1><span> 개</span></div></div>
      </div>

      <div class="contents">
        <div style="border-bottom: lightgray solid 1px; padding: 17px; display: grid; grid-template-columns: 80% 20%;"><div><h2 style="display: inline;">일간 차트 추이</h2></div><div style="text-align: right; color: gray;"><span>ⓘ 최근 갱신: {{ this.recentRefreshTime }}</span></div></div>
        <div style="padding-top: 20px;" id="chart"></div>
      </div>

  </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import { CountUp } from 'countup.js';
import dayjs from 'dayjs';

export default {
data() {
  return {
    timestampIn24Hours: [],
    timestampIn24Hours_ui: [],
    rankingData: [],
    dataSets: [],

    recentRefreshTime: null,
  }

},

mounted() {
  axios.all([
    axios.get('https://litverse.io/api/streaming-service/spotify/latest-two-weeks-chart/'),
    axios.get('https://litverse.io/api/etcapi/currenttime/'),
    axios.get('https://litverse.io/api/streaming-service/melon/higest-ranking-chart/')
  ])
  .then(axios.spread((response1, response2, response3) => {
    this.latest2WeeksData = response1.data;
    this.TitlesList = [...new Set(this.latest2WeeksData.map(item => item.title))];
    this.highestRankingChart = response3.data;


    this.currentTime = new Date(this.latest2WeeksData[this.latest2WeeksData.length - 1].timestamp);
    this.recentRefreshTime = dayjs(new Date(this.latest2WeeksData[this.latest2WeeksData.length - 1].timestamp)).add(1, 'day').format('YYYY/MM/DD 23:30')
  }))  
  .then(() => {
      this.latestChartRanking = this.latest2WeeksData.filter(item => item.timestamp === dayjs(new Date(this.latest2WeeksData[this.latest2WeeksData.length-1].timestamp)).format('YYYY-MM-DDT00:00:00'));

  //   const smallestRanking = this.latestChartRanking.reduce((minObj, currentObj) => {
  //     return currentObj.ranking < minObj.ranking ? currentObj : minObj;
  //   });

  //   this.CountUpNum('realtime-ranking', smallestRanking.ranking) // { ranking: 1 }

    const rankings = this.latest2WeeksData.map(item => item.ranking);
    const peakRanking = Math.min(...rankings)

    const latestData = this.latest2WeeksData.filter(item => item.timestamp === this.latest2WeeksData[this.latest2WeeksData.length - 1].timestamp);
    const topRankingItem = latestData.reduce((best, item) => {
      return item.ranking < best.ranking ? item:best;
    }, latestData[0])
    
    this.CountUpNum('realtime-ranking', topRankingItem.ranking)
    this.CountUpNum('chart-in-count', this.latestChartRanking.length)
    this.CountUpNum('peak-in-2weeks', peakRanking)


    for (let i of this.TitlesList) {
      this.rankingData = []
      this.filteredData = []
      this.timestampIn24Hours_ui = []
      for (let j = 0; j < 14; j++) {
        this.timestampToFindData = dayjs(new Date(this.currentTime - (j * 24 * 60 * 60 * 1000))).format('YYYY-MM-DDT00:00:00')
          this.timestampIn24Hours.unshift(this.timestampToFindData)
          this.timestampIn24Hours_ui.unshift(dayjs(new Date(this.currentTime - (j * 24 * 60 * 60 * 1000))).format('MM/DD'))
          var filteredData = this.latest2WeeksData.filter(item => item.timestamp === this.timestampToFindData && item.title === i);
          if (filteredData.length === 0) {
              this.rankingData.unshift(null)
          } else {
              this.rankingData.unshift(filteredData["0"].ranking)
          }
      }
      if (i === 'Magnetic' || i === 'Lucky Girl Syndrome') {
        this.chartColor = '#505d53'
      } else if (i === 'Cherish (My Love)' || i === 'Tick-Tack') {
        this.chartColor = '#9d9c6a'
      } else {
        this.chartColor = 'black'
      }
      this.dataSets.push(
        {
          name: i,
          type: 'line',
          lineStyle: {
            width: 4,
          },
          symbolSize: 8,
          triggerLineEvent: true,
          data: Object.values(this.rankingData),
          itemStyle: {
            color: this.chartColor
          }
        }
      )
    }    
    var myChart = echarts.init(document.getElementById('chart'));
    window.addEventListener('resize', function() {
      myChart.resize();
    })

    myChart.setOption({
      title: {
      },
      tooltip: {
        trigger: 'axis',
        triggerOn: 'mousemove',
      },
      xAxis: {
        data: this.timestampIn24Hours_ui
      },
      yAxis: {
        max: 1,
        min: 200,
        inverse: true,
      },
      series: this.dataSets,
      grid: {
        left: 30,
        right: 0,
      },
      legend: {
        left: 'right',
        top: 'top',
      }
    })
  })
},
methods: {
  CountUpNum(id, goalNum) {
      const countUp = new CountUp(id, goalNum);
      if (!countUp.error) {
        countUp.start();
      } else {
        console.error(countUp.error);
      }
  },    
},
}


</script>

<style>
.melon-overview {
  display: grid; 
  place-items: center;
  width: 85%; 
  grid-template-columns: 25% 25% 25% 25%;
  padding-top: 80px;
  padding-bottom: 80px;
}

.melon-overview > div {
display: grid;
place-items: center;
text-align: left;
width: 100%;
}


.contents {
width: 85%;
}

#chart {
width: 100%;
height: 500px; /* ECharts 차트가 보이도록 크기를 지정 */
}
</style>