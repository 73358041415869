<template>
    <div style="height: 56px;"></div>
    <div style="width: 100%; display: grid; place-items: center;">
        <div class="melon-overview">
            <div style="display: grid; place-items: center; text-align: center;"><h1>종합</h1></div>
            <div style="border-right: lightgray solid 1px; border-left: lightgray solid 1px;"><div><h4>평균 순위</h4><h1 style="display: inline;" id="average-ranking"></h1><t> 위</t></div></div>
            <div style="border-right: lightgray solid 1px; "><div><h4>2주 내 최고 순위</h4><h1 style="display: inline;" id="peak-in-2weeks"></h1><t> 위</t></div></div>
            <div style=""><img width="200px" src="@/assets/images/illit-logo-black.png"></div>
        </div>

        <div class="contents">
          <div style="border-bottom: lightgray solid 1px; padding: 17px; display: grid; grid-template-columns: 80% 20%;"><div><h2 style="display: inline;">일간 차트 추이</h2></div><div style="text-align: right; color: gray;"><t>ⓘ 최근 갱신: {{ this.latestRefreshTime }}</t></div></div>
          <div style="padding-top: 20px; width: 100%; height: 500px;" id="overview-chart"></div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
import { CountUp } from 'countup.js';
import dayjs from 'dayjs';

export default {
    data() {
        return {
        timestampIn2Weeks: [],
        timestampIn2Weeks_ui: [],
        rankingData: [],
        dataSets: [],

        peakRanking: null,
        latestRefreshTime: null,
        }
    },
    mounted() {
        axios.all([
            axios.get('https://litverse.io/api/streaming-service/overview/'),
            axios.get('https://litverse.io/api/etcapi/currenttime/'),            
        ])
        .then(axios.spread((response1, response2) => {
            const melonData = response1.data['melon_daily_chart']
            const genieData = response1.data['genie_daily_chart']
            const spotifyData = response1.data['spotify_daily_chart']
            const applemusicData = response1.data['applemusic_daily_chart']

            this.currentTime = new Date(melonData[melonData.length - 1][5])
            this.latestRefreshTime = dayjs(new Date(melonData[melonData.length - 1][5])).add(1, 'day').format('YYYY/MM/DD 23:30')
            // this.currentTime = new Date(response2.data)
            
            this.abcde = response2.data

            this.spotifyRankingData = []
            this.melonRankingData = []
            this.genieRankingData = []
            this.applemusicRankingData = []
            this.isFindTrue = false

            for (let i = 0; i < 14; i++) {
                this.isFindTrue = false

                this.timestampToFindData = dayjs(new Date(this.currentTime - (i * 24 * 60 * 60 * 1000))).format('YYYY-MM-DDT00:00:00')
                this.timestampIn2Weeks.unshift(this.timestampToFindData)
                this.timestampIn2Weeks_ui.unshift(dayjs(new Date(this.currentTime - (i * 24 * 60 * 60 * 1000))).format('MM/DD'))



                for (let i = 0; i < spotifyData.length; i++) {
                    if (spotifyData[i][7] === this.timestampToFindData) {
                        this.spotifyRankingData.unshift(spotifyData[i][0])
                        this.spotifyRankingTitle = spotifyData[i][1]
                        this.isFindTrue = true 
                    }
                }
                if (this.isFindTrue == false) {
                    this.spotifyRankingData.unshift(null)                
                }

                this.isFindTrue = false
                for (let i = 0; i < melonData.length; i++) {
                    if (melonData[i][5] === this.timestampToFindData) {
                        this.melonRankingData.unshift(melonData[i][0])
                        this.melonRankingTitle = melonData[i][1]
                        this.isFindTrue = true 
                    }
                }
                if (this.isFindTrue == false) {
                    this.melonRankingData.unshift(null)
                }

                this.isFindTrue = false
                for (let i = 0; i < genieData.length; i++) {
                    if (genieData[i][4] === this.timestampToFindData) {
                        this.genieRankingData.unshift(genieData[i][0])
                        this.genieRankingTitle = genieData[i][1]
                        this.isFindTrue = true
                    }
                }
                if (this.isFindTrue == false) {
                    this.genieRankingData.unshift(null)
                }

                this.isFindTrue = false
                for (let i = 0; i < applemusicData.length; i++) {
                    if (applemusicData[i][4] === this.timestampToFindData) {
                        this.applemusicRankingData.unshift(applemusicData[i][1])
                        this.applemusicRankingTitle = applemusicData[i][0]
                        this.isFindTrue = true
                    }
                }
                if (this.isFindTrue == false) {
                    this.applemusicRankingData.unshift(null)
                }
            }
            var combinedData = [...this.melonRankingData, ...this.genieRankingData, ...this.spotifyRankingData, ...this.applemusicRankingData]
            combinedData = combinedData.filter(value => value !== null);
            
            const peakRanking = Math.min(...combinedData)
            this.CountUpNum('peak-in-2weeks', peakRanking)

            for (let idx = 0; idx < [this.melonRankingData, this.genieRankingData, this.spotifyRankingData, this.applemusicRankingData].length; idx++) {
                const i = [this.melonRankingData, this.genieRankingData, this.spotifyRankingData, this.applemusicRankingData][idx];
                const j = ['멜론 ('+this.melonRankingTitle+')', '지니 ('+this.genieRankingTitle+')', '스포티파이 ('+this.spotifyRankingTitle+')', '애플 뮤직 ('+this.applemusicRankingTitle+')'][idx];
                const k = ['#00cd3b', '#0096ff', '#080808', '#fa3f57'][idx];

                this.dataSets.push(
                {
                    name: j,
                    type: 'line',
                    lineStyle: {
                    width: 4,
                    },
                    symbolSize: 8,
                    triggerLineEvent: true,
                    data: Object.values(i),
                    itemStyle: {
                    color: k,
                    }
                }
                )                     
            }
            this.rankingAverage = (this.melonRankingData[this.melonRankingData.length-1] + this.genieRankingData[this.genieRankingData.length-1] + this.spotifyRankingData[this.spotifyRankingData.length-1] + this.applemusicRankingData[this.applemusicRankingData.length-1]) / 4
            this.CountUpNum('average-ranking', this.rankingAverage)

            this.makeChart('overview-chart', '', this.timestampIn2Weeks_ui, this.dataSets)


        }))
    },
    methods: {
        CountUpNum(id, goalNum) {
            const countUp = new CountUp(id, goalNum);
            if (!countUp.error) {
                countUp.start();
            } else {
                console.error(countUp.error);
            }
        }, 
        makeChart(elementId, title, data, series) {
            var myChart = echarts.init(document.getElementById(elementId));
            window.addEventListener('resize', function() {
                myChart.resize();
            })

            myChart.setOption({
                title: {
                    text: title
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove',
                },
                xAxis: {
                    data: data,
                },
                yAxis: {
                    type: 'value',
                    scale: 'true',
                    inverse: true,
                    // max: 100,
                    // min: 1,
                },
                series: series,
                grid: {
                    left: 80,
                    right: 0,
                },
                legend: {
                    left: 'right',
                    top: 'top',
                } 
            })
        },   
    },
}


</script>

<style>
.melon-overview {
    display: grid; 
    place-items: center;
    width: 85%; 
    grid-template-columns: 25% 25% 25% 25%;
    padding-top: 80px;
    padding-bottom: 80px;
}

.melon-overview > div {
  display: grid;
  place-items: center;
  text-align: left;
  width: 100%;
}


.contents {
  width: 85%;
}

#chart {
  width: 100%;
  height: 500px; /* ECharts 차트가 보이도록 크기를 지정 */
}
</style>