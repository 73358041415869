import { createWebHistory, createRouter } from 'vue-router'

import HomePage from '../views/Desktop_View/HomePage.vue'
import Youtube_MusicVideo from '../views/Desktop_View/Youtube_MusicVideo.vue' 
import Youtube_ILLIT_Channel from '../views/Desktop_View/Youtube_ILLIT_Channel.vue' 
import streaming_service_melon from '../views/Desktop_View/Streaming_Service_Melon.vue'
import streaming_service_genie from '../views/Desktop_View/Streaming_Service_Genie.vue'
import streaming_service_spotify from '../views/Desktop_View/Streaming_Service_Spotify.vue'
import Streaming_Service_AppleMusic from '@/views/Desktop_View/Streaming_Service_AppleMusic.vue';

import YouTube_MusicVideo_DetailInfo from '@/views/Desktop_View/YouTube_MusicVideo_DetailInfo.vue';
import YouTube_ILLIT_Channel_DetailInfo from '@/views/Desktop_View/YouTube_ILLIT_Channel_DetailInfo.vue';
import Streaming_Service_Overview from '@/views/Desktop_View/Streaming_Service_Overview.vue';

const routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/youtube/musicvideo',
    component: Youtube_MusicVideo,
  },
  {
    path: '/youtube/illit-channel',
    component: Youtube_ILLIT_Channel,
  },
  {
    path: '/streaming-service/overview',
    component: Streaming_Service_Overview,
  },
  {
    path: '/streaming-service/melon',
    component: streaming_service_melon,
  },
  {
    path: '/streaming-service/genie',
    component: streaming_service_genie,
  },
  {
    path: '/streaming-service/spotify',
    component: streaming_service_spotify,
  },
  {
    path: '/streaming-service/apple-music',
    component: Streaming_Service_AppleMusic
  },
  {
    path: '/youtube/musicvideo/:id',
    component: YouTube_MusicVideo_DetailInfo,
    props: true,
  },
  {
    path: '/youtube/illit-channel/:id',
    component: YouTube_ILLIT_Channel_DetailInfo,
    props: true, 
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/Desktop_View/NotFound.vue'),
  },
];

export default createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes,
});
