import { createRouter, createWebHistory } from "vue-router";

const routes = [
    { path: '/', component: () => import('@/views/Mobile_View/MobileHome.vue') },
    { path: '/streaming-service/overview', component: () => import('@/views/Mobile_View/Streaming_Service/Streaming_Service_Overview.vue') },
    { path: '/streaming-service/melon', component: () => import('@/views/Mobile_View/Streaming_Service/Streaming_Service_Melon.vue') },
    { path: '/streaming-service/genie', component: () => import('@/views/Mobile_View/Streaming_Service/Streaming_Service_Genie.vue') },
    { path: '/streaming-service/spotify', component: () => import('@/views/Mobile_View/Streaming_Service/Streaming_Service_Spotify.vue') },
    { path: '/streaming-service/apple-music', component: () => import('@/views/Mobile_View/Streaming_Service/Streaming_Service_AppleMusic.vue') },

    { path: '/youtube/illit-channel', component: () => import('@/views/Mobile_View/YouTube/Youtube_ILLIT_Channel.vue') },
    { path: '/youtube/musicvideo', component: () => import('@/views/Mobile_View/YouTube/Youtube_MusicVideo.vue') },

    {
        path: '/youtube/illit-channel/:id',
        component: () => import('@/views/Mobile_View/YouTube/YouTube_ILLIT_Channel_DetailInfo.vue'),
        props: true, 
    },
    {
        path: '/youtube/musicvideo/:id',
        component: () => import('@/views/Mobile_View/YouTube/YouTube_MusicVideo_DetailInfo.vue'),
        props: true, 
    },

    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/Mobile_View/NotFound.vue'),
    },
];

export default  createRouter({
    history: createWebHistory(),
    routes,
});