<template>
    <div style="height: 136px;"></div>
    <div class="mv-overview" style="display: grid; place-items: center;">
        <div style="width: 95%; display: grid; grid-template-columns: 50% 50%; border-bottom: lightgray solid 1px; padding-bottom: 25px; margin-bottom: 48px;">
            <div style="padding: 0;"><h2>{{ this.video_title }}: 자세히 보기</h2></div>
            <div style="display: flex; align-items: center; justify-content: right; padding: 0;"><span style="color: gray; ">  ⓘ 최근 갱신: {{ this.recentUpdateTime }}</span></div>
        </div>            
        
        <div style="display: grid; grid-template-columns: 50% 50%; width: 95%; padding-bottom: 48px;">
            <div style="text-align: right; border-right: lightgray solid 1px;"><iframe width="640" height="360" v-bind:src="`https://www.youtube.com/embed/${id}?si=KZplLVgVVEwgLBGo`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>
            <div>
                <h4>{{ this.video_title }}</h4>
                <span>Video_Id: </span><span>{{ this.video_id }}</span><br>
                <span>Uploaded_At: </span><span>{{ this.uploaded_at }}</span><br><br>
                <h5>조회수</h5><h2 style="display: inline;">{{ this.view_count }}</h2><span> 회</span><br><br>
                <h5>좋아요</h5><h2 style="display: inline;">{{ this.like_count }}</h2><span> 개</span><br><br>
                <h5>댓글</h5><h2 style="display: inline;">{{ this.comment_count }}</h2><span> 개</span>
            </div>
        </div>
        <div class="chart-div">
            <div id="view-chart"></div>
            <div id="like-chart"></div>
            <div id="comment-chart"></div>
        </div>    
    </div>

</template>

<style>
body {
  user-select: text;
}

.mv-overview > div > div {
    padding: 50px;
}

.chart-div {
    width: 100%;
    display: grid;
    place-items: center;
}

.chart-div > div {
    padding: 50px;
    border-top: lightgray solid 1px;
    width: 95%;
    height: 500px;
}
</style>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import axios from 'axios';

export default {
    props: ['id'],
    data() {
        return {
            video_id: this.id,
            mv_json: null,
            video_title: null,
            uploaded_at: null,
            view_count: null,
            like_count: null,
            comment_count: null,
            recentUpdateTime: null,
        }
    },
    mounted() {
        axios.get(`https://litverse.io/api/youtube/mv/${this.video_id}/`)
        .then(response => {
            this.mv_json = response.data;

            this.video_title = this.mv_json[0].video_title;
            this.view_count = this.mv_json[this.mv_json.length - 1].view_count.toLocaleString();
            this.like_count = this.mv_json[this.mv_json.length - 1].like_count.toLocaleString();
            this.comment_count = this.mv_json[this.mv_json.length - 1].comment_count.toLocaleString();
            this.uploaded_at = this.mv_json[0].uploaded_at;

            this.recentUpdateTime = dayjs(new Date(this.mv_json[this.mv_json.length - 1].timestamp)).format('YYYY/MM/DD HH:00');
        })
        
        .then(() => {
            this.filteredData = []
            this.xAxisData_raw = []
            this.view_countData = []
            this.like_countData = []
            this.comment_countData = []
            this.xAxisData = []
            for (let i = 0; i < 14; i++) {
                this.timestampToFindData = dayjs(new Date(this.mv_json[this.mv_json.length - 1].timestamp) - new Date(i * 24 * 60 * 60 * 1000)).format('YYYY-MM-DDT00:00:00')
                this.xAxisData_raw.unshift(this.timestampToFindData)
                this.xAxisData.unshift(dayjs(this.timestampToFindData).format('YYYY/MM/DD'))
                var filteredData = this.mv_json.filter(item => item.timestamp === this.timestampToFindData);
                if (filteredData.length === 0) {
                    this.view_countData.unshift(null)
                    this.like_countData.unshift(null)
                    this.comment_countData.unshift(null)
                } else {
                    this.view_countData.unshift(filteredData["0"].view_count)
                    this.like_countData.unshift(filteredData["0"].like_count)
                    this.comment_countData.unshift(filteredData["0"].comment_count)
                }
            }
            

            this.view_countSets = []
            this.like_countSets = []
            this.comment_countSets = []
            for (const [i, j, k] of [[this.view_countSets, this.view_countData, '조회수'], [this.like_countSets,  this.like_countData, '좋아요'], [ this.comment_countSets,  this.comment_countData, '댓글']]) {
                if (k === '조회수') {
                    this.chartColor = '#fe0002'
                }
                if (k === '좋아요') {
                    this.chartColor = '#34a1fe'
                }
                if (k === '댓글') {
                    this.chartColor = '#808080'
                }
                i.push(
                {
                    name: k,
                    type: 'line',
                    lineStyle: {
                    width: 4,
                    },
                    symbolSize: 8,
                    triggerLineEvent: true,
                    data: Object.values(j),
                    itemStyle: {
                    color: this.chartColor
                    }
                }
                )
            }
            this.makeChart('view-chart', '조회수 추이' , this.xAxisData, this.view_countSets["0"])
            this.makeChart('like-chart', '좋아요 수 추이' ,this.xAxisData, this.like_countSets["0"])
            this.makeChart('comment-chart','댓글 수 추이' , this.xAxisData, this.comment_countSets["0"])
        })
    },
    methods: {
        makeChart(elementId, title, data, series) {
            var myChart = echarts.init(document.getElementById(elementId));
            window.addEventListener('resize', function() {
                myChart.resize();
            })

            myChart.setOption({
                title: {
                    text: title
                },
                tooltip: {
                    trigger: 'axis',
                    triggerOn: 'mousemove',
                },
                xAxis: {
                    data: data,
                },
                yAxis: {
                    type: 'value',
                    scale: 'true'
                },
                series: series,
                grid: {
                left: 80,
                right: 0,
                },
                legend: {
                left: 'right',
                top: 'top',
                } 
            })
        }
    }
}
</script>