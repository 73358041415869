<template>
    <div style="height: 56px;"></div>
    <div class="content-1">
        <video autoplay muted loop width="100%" style="padding-bottom: 40px;">
            <source src="@/assets/videos/ILLIT (아일릿) ‘Magnetic’ Official MV [Vk5-c_v4gMU].mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <div class="music-video-overview">
            <div>
                <h4>조회수 합계:</h4>
                <h1 id="viewcount-sum" style="display: inline;"></h1><span> 회</span>
            </div>
            <div>
                <h4>좋아요 수 합계:</h4>
                <h1 id="likecount-sum" style="display: inline;"></h1><span> 개</span>                
            </div>
            <div>
                <h4>댓글 수 합계:</h4>
                <h1 id="commentcount-sum" style="display: inline;"></h1><span> 개</span>                
            </div>
            <div><img  
                    :src="illit_logo_white" style="width: 200px; opacity: 0.85;">
            </div>
        </div>
        <div style="width: 95%; display: grid; grid-template-columns: 50% 50%; border-top: none; padding: 0;">
            <div><h1>뮤직 비디오</h1></div>
            <div style="display: flex; align-items: center; justify-content: right;"><span style="color: gray; ">  ⓘ 최근 갱신: {{ this.recentUpdateTime }}</span></div>
        </div>
        <div style="display: grid; grid-template-columns: 50% 50%; width: 95%; padding: 80px;">
            <div class="video-div" style="text-align: right;"><iframe width="640" height="360" src="https://www.youtube.com/embed/Vk5-c_v4gMU?si=KZplLVgVVEwgLBGo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>
            <div style="display: flex; align-items: center;">
                <div>
                    <h4>ILLIT (아일릿) ‘Magnetic’ Official MV</h4><br>
                    <span>조회수</span><br>
                    <h1 style="display: inline;">{{ this.viewCountList[3] }}</h1><span> 회</span>
                    <br><br><span>좋아요</span><br>
                    <h1 style="display: inline;">{{ this.likeCountList[3] }}</h1><span> 개</span><br>
                    <br><router-link to="/youtube/musicvideo/Vk5-c_v4gMU"> <button type="button" class="btn btn-outline-info">자세히 보기</button></router-link> <a href="https://www.youtube.com/watch?v=Vk5-c_v4gMU" target="_blank"><button type="button" class="btn btn-outline-primary">영상으로 이동</button></a>                    
                </div>
            </div>
        </div>        
        <div style="display: grid; grid-template-columns: 50% 50%; width: 95%; padding: 80px;">
            <div class="video-div" style="text-align: right;"><iframe width="640" height="360" src="https://www.youtube.com/embed/tbDGl7jEazA?si=4lv2SNPLN1taM3vu" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>
            <div style="display: flex; align-items: center;">
                <div>
                    <h4>ILLIT (아일릿) ‘Cherish (My Love)’ Official MV</h4><br>
                    <span>조회수</span><br>
                    <h1 style="display: inline;">{{ this.viewCountList[1] }}</h1><span> 회</span>
                    <br><br><span>좋아요</span><br>
                    <h1 style="display: inline;">{{ this.likeCountList[1] }}</h1><span> 개</span><br>
                    <br><router-link to="/youtube/musicvideo/tbDGl7jEazA"> <button type="button" class="btn btn-outline-info">자세히 보기</button></router-link> <a href="https://www.youtube.com/watch?v=tbDGl7jEazA" target="_blank"><button type="button" class="btn btn-outline-primary">영상으로 이동</button></a>             
                </div>
            </div>
        </div>  
        <div style="display: grid; grid-template-columns: 50% 50%; width: 95%; padding: 80px;">
            <div class="video-div" style="text-align: right;"><iframe width="640" height="360" src="https://www.youtube.com/embed/UCmgGZbfjmk?si=ixel53Zy6qqEwC6Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>
            <div style="display: flex; align-items: center;">
                <div>
                    <h4>ILLIT (아일릿) 'Lucky Girl Syndrome' Official MV</h4><br>
                    <span>조회수</span><br>
                    <h1 style="display: inline;">{{ this.viewCountList[2] }}</h1><span> 회</span>
                    <br><br><span>좋아요</span><br>
                    <h1 style="display: inline;">{{ this.likeCountList[2] }}</h1><span> 개</span><br>
                    <br><router-link to="/youtube/musicvideo/UCmgGZbfjmk"> <button type="button" class="btn btn-outline-info">자세히 보기</button></router-link> <a href="https://www.youtube.com/watch?v=UCmgGZbfjmk" target="_blank"><button type="button" class="btn btn-outline-primary">영상으로 이동</button></a>
                </div>
            </div>
        </div>  
        <div style="display: grid; grid-template-columns: 50% 50%; width: 95%; padding: 80px;">
            <div class="video-div" style="text-align: right;"><iframe width="640" height="360" src="https://www.youtube.com/embed/-nEGVrzPaiU?si=Em7EBXfgPyzl87Rj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>
            <div style="display: flex; align-items: center;">
                <div>
                    <h4>ILLIT (아일릿) ‘Tick-Tack’ Official MV</h4><br>
                    <span>조회수</span><br>
                    <h1 style="display: inline;">{{ this.viewCountList[0] }}</h1><span> 회</span>
                    <br><br><span>좋아요</span><br>
                    <h1 style="display: inline;">{{ this.likeCountList[0] }}</h1><span> 개</span><br>
                    <br><router-link to="/youtube/musicvideo/-nEGVrzPaiU"> <button type="button" class="btn btn-outline-info">자세히 보기</button></router-link>  <a href="https://www.youtube.com/watch?v=-nEGVrzPaiU" target="_blank"><button type="button" class="btn btn-outline-primary">영상으로 이동</button></a>
                </div>                
            </div>
        </div>  
    </div>
</template>

<style>
.content-1 {
    display: grid;
    place-items: center;
    width: 100%;
}

.content-1 > div {
    border-top: lightgray solid 1px;
}

.content-1 > div > div {
    padding: 50px;
}

.video-div {
    border-right: lightgray solid 1px
}

.music-video-overview {
    position: absolute;
    top: 12.5vh;
    width: 82%;
    z-index: 991;
    border-top: none !important;
    color: rgba(255, 255, 255, 0.85);
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}
</style>

<script>
import dayjs from 'dayjs';
import axios from 'axios';
import { CountUp } from 'countup.js';

import illit_logo_white from '@/assets/images/illit-logo-white.png';
import illit_logo_1to2 from '@/assets/images/illit-logo-white-1to2.png';

export default {
    data() {
        return {
            recentUpdateTime: null,
            viewCountList: [0, 0, 0, 0],
            likeCountList: [0, 0, 0, 0],

            isMouseOver1: false,
            illit_logo_white, illit_logo_1to2,
        };
    },
    methods: {
        CountUpNum(id, goalNum) {
            const countUp = new CountUp(id, goalNum);
            if (!countUp.error) {
                countUp.start();
            } else {
                console.error(countUp.error);
            }
        },   
    },
    mounted() {
        axios.get('https://litverse.io/api/youtube/latest-musicvideo-info/')
        .then(response => {
            this.latestInfo = response.data;
            this.viewCount_sum = response.data.reduce((sum, video) => sum + video.view_count, 0);
            this.likeCount_sum = response.data.reduce((sum, video) => sum + video.like_count, 0);
            this.commentCount_sum = response.data.reduce((sum, video) => sum + video.comment_count, 0);

            this.recentUpdateTime = dayjs(new Date(response.data[0].timestamp)).format('YYYY/MM/DD HH:00')
            this.viewCountList = []
            this.likeCountList = []

            for (let i = 0; i < response.data.length; i++) {
                this.viewCountList.push(response.data[i].view_count.toLocaleString())
                this.likeCountList.push(response.data[i].like_count.toLocaleString())
            }

            this.CountUpNum('viewcount-sum', this.viewCount_sum)
            this.CountUpNum('likecount-sum', this.likeCount_sum)
            this.CountUpNum('commentcount-sum', this.commentCount_sum)
        })
    }
}    
</script>